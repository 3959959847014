@import "@repo/common/vars.scss";

.textDisplay {
  display: flex;
  align-items: center;
  margin: 5px 0;
  position: relative;
  min-width: 150px;
  border: solid 1px blue;

  p {
    height: 54px;
    border: solid 1px transparent;
    display: inline-block;
    border-radius: 3px;
    padding: 20px 5px 0 10px;
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin: 0px 5px;
  pointer-events: none;

  span {
    transition: all .1s ease-out;
    margin-top: 17.5px;
    margin-left: 5px;
  }
}
.small span {
  transition: all .1s ease-in;
  margin-top: 5px;
  font-size: standardSizes(14);
}