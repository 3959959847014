@import "@repo/common/vars.scss";
.segmented {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 150px;
  border: solid 1px transparent;
  border-radius: 3px;

  :global(.ant-segmented-item) {
    @apply bg-stone-200;
  }

  :global(.ant-segmented-item-selected) {
    @apply bg-white;
  }
}

.label {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: standardSizes(14);
}