@import "@repo/common/vars.scss";
.forgotPassword {
  color: blue;
  font-size: 16px;
  margin-left: auto;
  text-decoration: underline;
}

.splashBox {
  position: absolute;
  width: 500px;
  height: 300px;
  transform: rotate(-41deg);
  flex-shrink: 0;
  @apply bg-blue-600;
  top: 30%;
  right: -180px;
}
