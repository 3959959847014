@import "@repo/common/vars.scss";
.modal.modal {
  &.sideModal {
    margin-bottom: 12px;
    margin-right: 11px;
    margin-top: 13px;
    padding-bottom: 0;
    top: 0;
    
    :global {
      .ant-modal-content {
        height: calc(100vh - 25px); // related to margins top and bottom
      }
    }
  }

  &.fullHeight {
    top: 25px;

    :global {
      .ant-modal-content {
        height: calc(100vh - 50px); // related to margins top and bottom
      }
    }
  }

  :global {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
    }

    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  :global {
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}

.closeButton.closeButton {
  align-items: center;
  background-color: #f5f5f7;
  border: 1px solid #f5f5f7;
  display: flex;
  justify-content: center;
  height: 40px !important;
  width: 40px !important;
}
